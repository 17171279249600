import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
import Navbar from "../components/navbar"
import Helmet from "react-helmet"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import "./blog.scss"

const BlogTemplate = ({ data, pageContext }) => {
  console.log(pageContext)
  const post = data.contentfulBlog
  const next = pageContext.next
    ? {
      url: `/blog/${pageContext.next.slug}`,

    }
    : null
  const prev = pageContext.prev
    ? {
      url: `/blog/${pageContext.prev.slug}`,

    }
    : null
  return (
    <Layout>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>
      <SEO
        title={post.seoTitle}
        description={post.seoDescription}
        keywords={post.seoKeywords}
      />

      <Navbar />
      <div
        className="hero-header-image"
        style={{
          backgroundImage: `url(${post.featuredImage.fluid.src})`,
        }}
      ></div>
      <br />
      <div className="post-title px-3">
        <h1 className="font-weight-bold">{post.title}</h1>
      </div>
      <Container>
        <div
          className="post-content text-justify"
          dangerouslySetInnerHTML={{
            __html: `${post.content.childMarkdownRemark.html}`,
          }}
        />
        <div className="d-flex justify-content-between flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row prev-next-post-links">
          {prev && (
            <Link to={prev.url} className="mt-3">
              <FontAwesomeIcon
                className="mr-2 fa-lg"
                icon={faArrowLeft}
              />Предыдущая статья
            </Link>
          )}
          {next && (
            <Link to={next.url} className="mt-3">
              Следующая статья<FontAwesomeIcon
                className="ml-2 fa-lg"
                icon={faArrowRight}
              />
            </Link>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query BlogTemplate($id: String!) {
    contentfulBlog(id: { eq: $id }) {
      title
      id
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
      seoTitle
      seoDescription
      seoAuthor
      seoKeywords
      seoImage {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyContentfulFluid
          src
        }
      }
      featuredImage {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyContentfulFluid
          src
        }
      }
    }
  }
`
